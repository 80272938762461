import React from "react";
import { Col, Row, Table } from "react-bootstrap";

import Layout from "../components/layout";
import SEO from "../components/seo";

const ReasonPage = () => (
  <>
  <Layout>
    <SEO title="Reason" />

    <Row>
      <Col>
        <h1 className="h1-font">概要</h1>
        <p>This is JUNESS</p>
      </Col>
    </Row>

    <Row>
      <Table striped bordered>
        <tbody>
          <tr>
            <td>社名</td>
            <td>ジュネス</td>
          </tr>
          <tr>
            <td>代表者</td>
            <td>藤本　圭亮</td>
          </tr>
        </tbody>
      </Table>
    </Row>

  </Layout>
  </>
);

export default ReasonPage;
